import React from 'react';
import './App.css';
import resumePDF from './Resume.pdf'; 


function Header() {

  return (
    <header id="header">
      <h1>Finn Morris</h1>
      <p>Mechatronic Engineer &nbsp;&bull;&nbsp; Computer Scientist &nbsp; </p>
      <nav>
        <ul>
          <li><a href="https://github.com/finnmo" className="icon brands fa-github"><span className="label">Github</span>Github</a></li>
          <li><a href="https://www.linkedin.com/in/finn-morris" className="icon brands fa-linkedin"><span className="label">LinkedIn</span>LinkedIn</a></li>
          <li><a href="mailto: finn.morris00@gmail.com" className="icon solid fa-envelope"><span className="label">Email</span>Email</a></li>
          <li><a href={resumePDF} target="_blank" rel="noreferrer" className="icon solid fa-id-card"><span className="label"></span>Resume</a></li>
        </ul>
      </nav>
    </header>
  );
}

function App() {
  return (
    <div className="App is-preload">
      <div id="wrapper">
        <div id="bg"></div>
        <div id="overlay"></div>
        <div id="main">
          <Header />
          {/* Your main content here */}
        </div>
      </div>
    </div>
  );
}

export default App;
